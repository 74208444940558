import LeadForm from "../components/LeadForm";

const Form = () => {

    return (
        <div>
            <LeadForm />
        </div>
    );
}

export default Form;