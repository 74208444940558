import CustomerRole from '../models/CustomerRole'

export default abstract class CustomerRoleService {
  /**
   *
   * @param customerID
   * @return CustomerRole[]
   */
  static async getAll(customerID: number) {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/customerRoles')
    const jsonResponse = await response.json()

    const roles: CustomerRole[] = []

    jsonResponse.customerRoles.forEach((item: any) => {
      if (Number.parseInt(item.customer_id) !== customerID) {
        return
      }

      const role = new CustomerRole()
      role.id = item.id
      role.createdAt = item.created_at
      role.updatedAt = item.updated_at
      role.roleId = item.role_id
      role.roleLevel = item.role_level
      role.rolePublicName = item.role_public_name
      role.rolePublicView = item.role_public_view
      role.roleSorting = item.role_sorting
      role.customerId = item.customer_id

      roles.push(role)
    })

    return roles
  }
}
