import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ConfigProvider } from './contexts/config'
import FormComponent from './pages/Form'
import themeData from './theme'

type State = {}

type Props = {}

class App extends React.Component<Props, State> {
  render() {
    return (
      <ThemeProvider theme={themeData}>
        <BrowserRouter>
          <ConfigProvider>
            <Routes>
              <Route path="/" element={<FormComponent />} />
            </Routes>
          </ConfigProvider>
        </BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App
