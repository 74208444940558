export default class CustomerRole {
  id: number = 0
  customerId: number = 0
  roleId: number = 0
  rolePublicView: number = 0
  rolePublicName: string = ''
  roleSorting: number = 0
  roleLevel: number = 0
  createdAt: string = ''
  updatedAt: string = ''
}
