import React, { ComponentProps, ComponentType, JSXElementConstructor, RefObject } from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { useConfig } from '../contexts/config'
import Contact from '../models/Contact'
import Region from '../models/Region'
import Role from '../models/Role'
import CustomerRoleService from '../services/CustomerRoleService'
import { sendEvent } from '../services/ParentEvents'
import Button from './Button'
import Captcha from './Captcha'
import { Heading } from './Heading'
import Input from './Input'
import Select from './Select'

type Props = {
  url: string
}

type FormError = {
  name: string
  message: string
}

type State = {
  modes: { label: string; value: any }[]
  privacy: boolean
  showPrivacyError: boolean
  showSubjectError: boolean
  mode?: { label: string; value: any } | null
  showWaiting: boolean
  showError: boolean
  navigateTo?: string | null
  showCaptcha: boolean
  captchaToken: string | null
  captchaQuestion: string | null
  term: string
  open: boolean
  captchaResult: string | null
  showSuccess: boolean
  role: Role
  region: Region
  contact: Contact
  formErrors: FormError[]
  lastname?: string
  tel?: string
  email?: string
  zip?: string
  message?: string
}

const StyledForm = styled.form`
  margin: 40px 0px;
`

const StyledFormControl = styled.div`
  margin-bottom: 20px;
`

const StyledActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100%;
`

const EMAIL_REGEX = /^[\w+-]+(?:\.[\w+-]+)*@[\da-z]+(?:[.-][\da-z]+)*\.[a-z]{2,}$/iu
const PLZ_REGEX = /^\d{5}$/

class LeadForm extends React.Component<Props, State> {
  mailTo: string = process.env.REACT_APP_MAIL_TO ?? ''
  malFunctionRedirect: string = process.env.REACT_APP_MAL_FUNCTION_REDIRECT ?? ''
  widget: any = JSON.parse(process.env.REACT_APP_WIDGET ?? '{}')
  termsPage: string = process.env.REACT_APP_TERMS_PAGE ?? ''
  apiUrl: string = process.env.REACT_APP_API_URL ?? ''
  templateKey: string = process.env.REACT_APP_TEMPLATE_KEY ?? ''

  state: any = {
    privacy: false,
    showPrivacyError: false,
    showWaiting: false,
    showError: false,
    navigateTo: null,
    showSubjectError: false,
    showCaptcha: false,
    captchaToken: null,
    captchaQuestion: null,
    term: '',
    open: false,
    captchaResult: null,
    showSuccess: false,
    modes: [],
    mode: null,
    formErrors: [],
  }

  formRef: RefObject<HTMLFormElement>

  constructor(props: Props) {
    super(props)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleOnSelectInputChange = this.handleOnSelectInputChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleOnSelectTriggerClicked = this.handleOnSelectTriggerClicked.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.formRef = React.createRef()
    this.handlePrivacyChange = this.handlePrivacyChange.bind(this)
    this.handleCaptchaSubmit = this.handleCaptchaSubmit.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  async componentDidMount() {
    sendEvent({ name: 'start' })

    const tempRoles = await CustomerRoleService.getAll(this.widget.customer)
    const modes = []
    const tempModes: any[] = []
    const widgetRoles = this.widget.roles
    if (this.widget.roles !== undefined) {
      for (const item of tempRoles) {
        if (widgetRoles.includes(item.roleId)) {
          tempModes.push({
            value: item.roleId,
            label: this.getRoleLabel(item.id, item.rolePublicName),
          })
        }
      }
    }
    if (tempModes.length === 0 && this.widget.roles === undefined) {
      modes.push({
        value: Number.parseInt(this.widget.role),
        label: 'Anfrage',
      })
    }
    if (this.widget.roles !== undefined) {
      this.widget.roles.forEach(function (key: any) {
        for (const item of tempModes) {
          if (key === item.id) {
            modes.push(item)
          }
        }
      })
      modes.push(
        {
          value: this.templateKey === 'charm' ? 11 : 7,
          label: 'Anfrage Reparatur',
        },
        {
          value: this.templateKey === 'charm' ? 19 : 41,
          label: 'Anfrage Digital Media Services',
        }
      )
    }
    if (this.malFunctionRedirect !== undefined) {
      modes.push({
        value: 0,
        label: 'Störungsmeldung',
      })
    }
    if (this.mailTo !== undefined) {
      modes.push({
        value: -1,
        label: 'Presseanfrage',
      })
    }

    this.setState({ modes: modes })
  }

  getRoleLabel(id: number, label: string) {
    if (this.templateKey === 'charm') {
      switch (id) {
        case 5: {
          label = 'Anfrage Neuanlage'
          break
        }
        case 7: {
          label = 'Anfrage Modernisierung'
          break
        }
        case 9: {
          label = 'Anfrage Servicevertrag' //'Anfrage Service & Reparatur';
          break
        }
      }
    } else {
      switch (id) {
        case 5: {
          label = 'Anfrage Neuanlage'
          break
        }
        case 6: {
          label = 'Anfrage Modernisierung'
          break
        }
        case 14: {
          label = 'Anfrage Servicevertrag' //'Anfrage Service & Reparatur';
          break
        }
      }
    }
    return label
  }

  handlePrivacyChange(e: any) {
    this.setState({
      privacy: !this.state.privacy,
    })
  }

  handleCaptchaSubmit(result: string) {
    this.setState({ captchaResult: result }, () => {
      this.sendData()
    })
  }

  handleSubmitForm(e: any) {
    e.preventDefault()
    return this.sendData()
  }

  resetForm() {
    this.formRef.current?.reset()
    this.setState({
      privacy: false,
      showPrivacyError: false,
      mode: null,
      showWaiting: false,
      showError: false,
      navigateTo: null,
      showSubjectError: false,
      showCaptcha: false,
      captchaToken: null,
      captchaQuestion: null,
      term: '',
      open: false,
      captchaResult: null,
      showSuccess: false,
      formErrors: [],
    })
  }

  validate(): boolean {
    const formErrors: FormError[] = []
    const eventErrors: string[] = []

    const { lastname, tel, email, zip, privacy, mode } = this.state
    if (!mode) {
      formErrors.push({ name: 'mode', message: 'Bitte treffen Sie eine Auswahl' })
      eventErrors.push('SELECT.subject:unselected')
    }
    if (!lastname) {
      formErrors.push({ name: 'lastname', message: 'Bitte eingeben' })
      eventErrors.push('INPUT.lastname:empty')
    }
    if (!tel) {
      formErrors.push({ name: 'tel', message: 'Bitte eingeben' })
      eventErrors.push('INPUT.tel:empty')
    }
    if (!email) {
      formErrors.push({ name: 'email', message: 'Bitte eingeben' })
      eventErrors.push('INPUT.email:empty')
    } else if (!EMAIL_REGEX.test(email)) {
      formErrors.push({ name: 'email', message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
      eventErrors.push('INPUT.email:invalid')
    }
    if (!zip) {
      formErrors.push({ name: 'zip', message: 'Bitte geben Sie Ihre Postleitzahl ein' })
      eventErrors.push('INPUT.zip:empty')
    } else if (!PLZ_REGEX.test(zip)) {
      formErrors.push({ name: 'zip', message: 'Bitte geben Sie eine gültige Postleitzahl ein' })
      eventErrors.push('INPUT.zip:invalid')
    }
    if (!privacy) {
      formErrors.push({ name: 'privacy', message: 'Bitte nehmen Sie unsere Datenschutzerklärung zur Kenntnis.' })
      eventErrors.push('CHECK.privacy:unchecked')
    }
    this.setState({ formErrors: formErrors })

    if (eventErrors.length > 0) {
      sendEvent({ name: 'error', data: eventErrors.join('|') })
    }

    return formErrors.length === 0
  }

  async sendData() {
    const form = this.formRef.current

    if (!form) {
      // something went totally wrong :D
      return
    }

    // validate
    if (!this.validate()) {
      return
    }

    sendEvent({ name: 'send' })

    // serialize form
    const formData = new FormData(form)
    const data = new URLSearchParams()
    for (const pair of formData.entries()) {
      data.append('lead[' + pair[0] + ']', pair[1].toString())
    }
    data.append('lead[role]', this.widget.role)
    data.append('lead[subject]', this.widget.subject)
    data.append('lead[newsletter]', '0')
    data.append('lead[customer]', this.widget.customer)
    data.append('lead[widget_identifier]', this.widget.identifier)
    data.append('lead[widget_url]', this.props.url)
    data.append('widget[customer]', this.widget.customer)
    data.append('widget[role]', this.widget.role)
    data.append('widget[subject]', this.widget.subject)
    data.append('widget[type]', this.widget.type)
    data.append('widget[identifier]', this.widget.identifier)
    data.append('widget[referer]', window.location.href)
    data.append('widget[cid]', '')
    data.append('lead[company]', '')
    data.append('lead[job]', '')

    // build captcha query if result provided
    const { captchaToken, captchaResult } = this.state

    let captchaQuery = ''
    if (captchaToken && captchaResult) {
      captchaQuery = `?token=${captchaToken}&value=${captchaResult}`
    }

    // send lead to api
    this.setState({ showWaiting: true, showCaptcha: false })
    const response = await fetch(this.apiUrl + '/api/leads' + captchaQuery, {
      body: data,
      method: 'POST',
    })

    // check if captcha needed
    if (response.status === 499) {
      // show captcha
      const json = await response.json()
      this.setState({
        showCaptcha: true,
        captchaToken: json.captcha.token,
        captchaQuestion: json.captcha.label,
        captchaResult: null,
        showWaiting: false,
      })
      return false
    }

    if (response.status === 200 || response.status === 201) {
      // continue
      this.setState({
        showWaiting: false,
        showSuccess: true,
      })

      sendEvent({ name: 'submit' })

      return false
    }

    this.setState({
      showWaiting: false,
      showError: true,
    })

    return false
  }

  handleCancel(e: any) {
    this.setState({ navigateTo: '/' })
  }

  handleOnSelectInputChange(e: any) {
    this.setState({ term: e.target.value })
    const modes = this.state.modes.filter((mode: { label: string; value: any }) =>
      mode.label.toLowerCase().includes(e.target.value.toLowerCase())
    )
    this.setState({ modes: modes })
  }

  handleOnSelect(mode: { label: string; value: any }) {
    this.setState({
      mode: mode,
      open: false,
    })
  }

  handleOnSelectTriggerClicked(e: any) {
    this.setState({ open: !this.state.open })
  }

  getErrorMessage(name: string): string | undefined {
    const formError: FormError = this.state.formErrors.find((error: FormError) => error.name === name)
    return formError?.message
  }

  renderForm() {
    const { showPrivacyError } = this.state

    return (
      <div>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('lastname')}
            label="Vor- und Zuname"
            name="lastname"
            type="text"
            onChange={(e: any) => this.setState({ lastname: e.target.value })}
          />
        </StyledFormControl>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('tel')}
            label="Telefon"
            name="tel"
            type="tel"
            onChange={(e: any) => this.setState({ tel: e.target.value })}
          />
        </StyledFormControl>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('email')}
            label="E-Mail"
            name="email"
            type="text"
            onChange={(e: any) => this.setState({ email: e.target.value })}
          />
        </StyledFormControl>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('zip')}
            hint={'Die Angabe der PLZ ist für eine Zustellung an Ihre regionale Beratung notwendig.'}
            label="PLZ"
            name="zip"
            type="text"
            onChange={(e: any) => this.setState({ zip: e.target.value })}
          />
        </StyledFormControl>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('message')}
            label="Ihre Nachricht"
            type="textarea"
            name="message"
            onChange={(e: any) => this.setState({ message: e.target.value })}
          />
        </StyledFormControl>
        <StyledFormControl>
          <Input
            errorMessage={this.getErrorMessage('privacy')}
            label={"Ich habe die <a href='" + this.termsPage + "'>Datenschutzerklärung</a> zur Kenntnis genommen."}
            type="checkbox"
            name="privacy"
            onChange={this.handlePrivacyChange}
          />
        </StyledFormControl>
        <StyledActionContainer>
          <Button label="Senden" type="submit" onClick={() => {}} />
        </StyledActionContainer>
        <div style={{ margin: '40px 0px', fontSize: '15px' }}>* sind Pflichtfelder</div>
      </div>
    )
  }

  render() {
    const {
      open,
      mode,
      modes,
      navigateTo,
      showSubjectError,
      showWaiting,
      showError,
      showCaptcha,
      captchaQuestion,
      showSuccess,
    } = this.state

    if (navigateTo) {
      return <Navigate to={navigateTo} />
    }

    if (showError) {
      return (
        <div>
          <Heading kind="h2">Es ist ein Fehler aufgetreten!</Heading>
          <p>
            Wahrscheinlich existiert die von Ihnen eingegebene PLZ nicht. Bitte prüfen Sie die Richtigkeit Ihrer Angaben
            und versuchen Sie es erneut.
          </p>
        </div>
      )
    }

    if (showSuccess) {
      return (
        <div>
          <Heading kind="h2">Vielen Dank!</Heading>
          <p>Ihre Nachricht ist bei uns eingegangen und Ihre regionale Beratung wurde informiert!</p>
          <p>
            Sie erhalten zudem eine Eingangsbestätigung per E-Mail, in der Sie weitere Kontaktdaten Ihrer Beraterin oder
            Ihres Beraters vorfinden, um z.B. weitere Anhänge mitzusenden.
          </p>
          <Button outlined label="Zurück" onClick={this.resetForm} />
        </div>
      )
    }

    let content = null

    if (mode !== null && mode.value === 0) {
      // show störungsform
      content = (
        <Button label="Zum Störungsformular" onClick={() => (window.location.href = this.malFunctionRedirect)} />
      )
    } else if (mode !== null && mode.value === -1) {
      // show mailto button
      content = <Button label="E-Mail senden" onClick={() => (window.location.href = 'mailto:' + this.mailTo)} />
    } else {
      content = this.renderForm()
    }

    return (
      <div>
        {showWaiting && <p>Senden...</p>}
        {showCaptcha && <Captcha question={captchaQuestion} onSubmit={this.handleCaptchaSubmit} />}
        <div style={{ display: showCaptcha || showWaiting ? 'none' : 'block' }}>
          <StyledForm ref={this.formRef} onSubmit={this.handleSubmitForm}>
            <StyledFormControl>
              <Select
                entries={modes}
                open={open}
                placeholder="Treffen Sie bitte eine Auswahl *"
                selected={mode}
                errorMessage={this.getErrorMessage('mode')}
                onChange={this.handleOnSelectInputChange}
                onSelect={this.handleOnSelect}
                onTriggerClicked={this.handleOnSelectTriggerClicked}
              />
            </StyledFormControl>
            {content}
          </StyledForm>
        </div>
      </div>
    )
  }
}

export default function WithUrl() {
  const config = useConfig()

  return <LeadForm url={config.url} />
}
