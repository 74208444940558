type ParentEvents = {
  name: string,
  data?: string
}

export function sendEvent(event: ParentEvents) {
  if (!window.parent) return;

  let e = `product-widget-${event.name}`;
  if (event.data) {
    e += `:${event.data}`;
  }

  window.parent.postMessage(e, "*");
}